import './App.css';
import Card from './components/card/Card';
import Header from './components/header/Header';
import Modes_list from './components/modes_list/Modes_list';
import Modal_window from './components/modal_window/Modal_window';
import { useState } from 'react';

function App() {
  const [active_timers, set_active_timers] = useState([]);

  function select_timer(timer) {
    const newTimer = { ...timer }; // Создаем новый объект таймера
    set_active_timers((prevTimers) => [...prevTimers, newTimer]);
  }

  function delete_timer(id) {
    set_active_timers(active_timers.filter((obj) => obj.id != id));
  }

  return (
    <div className="App">
      <Header />
      <Modes_list select_timer={select_timer} />
      <div className="cards_container">
        {active_timers.length === 0 ? (
          <div>
            <Modal_window set_active_timers={set_active_timers} />
          </div>
        ) : (
          active_timers.map((item) => (
            <Card
              key={item.id}
              id={item.id}
              image={item.image}
              name={item.name}
              timer={item.timer}
              restart={item.restart}
              restartOn={item.restartOn}
              delete_timer={delete_timer}
              timerNotification={item.timerNotification}
              audioNotificaton={item.audioNotificaton}
              finishNotification={item.finishNotification}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default App;
