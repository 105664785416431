import React, { useEffect, useState } from 'react';

const CountdownTimer = ({
  initialMinutes,
  initialSeconds,
  isRunning,
  timerNotification,
  audioNotificaton,
  finishNotification,
  restart,
  isRestartOn,
}) => {
  const totalSeconds = initialMinutes * 60 + initialSeconds;
  const [remainingTime, setRemainingTime] = useState(totalSeconds);
  const workerRef = React.useRef();

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('./timerWorker.js', import.meta.url)
    );

    workerRef.current.onmessage = (e) => {
      const { remainingTime, finished } = e.data;

      // Аудио уведомление о скором срабатывании таймера
      if (remainingTime === timerNotification) {
        const notificationAudio = new Audio(audioNotificaton);
        notificationAudio.play();
      }

      if (finished) {
        // Воспроизводим звук в основном потоке
        const endAudio = new Audio(finishNotification);
        endAudio.play();
      } else {
        setRemainingTime(remainingTime);
      }
    };

    return () => {
      workerRef.current.terminate();
    };
  }, [finishNotification]);

  useEffect(() => {
    // запуск таймера
    if (isRunning) {
      workerRef.current.postMessage({
        command: 'start',
        data: { totalSeconds },
      });
    }
  }, [isRunning, totalSeconds]);

  // сброс таймера
  useEffect(() => {
    if (isRunning === false) {
      workerRef.current.postMessage({
        command: 'stop',
        data: { totalSeconds },
      });
    }
  }, [isRunning]);

  // рестарт таймера
  if (remainingTime === 0 && restart && isRestartOn) {
    workerRef.current.postMessage({
      command: 'reset',
      data: { totalSeconds },
    });
  }

  useEffect(() => {}, [restart, isRestartOn]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div>
      <p>
        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </p>
    </div>
  );
};

export default CountdownTimer;
