import React from 'react';
import './header.css';
import logo from '../header/header_logo.png';
const Header = () => {
  return (
    <nav>
      <div className="header_wrapper">
        <img src={logo} alt="" className="logo" />
        <p className="header_name">Dota2Timer</p>
      </div>
    </nav>
  );
};

export default Header;
