import React, { useState } from 'react';
import './modal_window.css';
import pudge_icon from './icons/pudge_icon.png';
import sound_icon from './icons/sound_icon.png';
import arrow_bottom from './icons/arrow_bottom_open.png';
import opening_speech_audio from './opening_speech1.mp4';

function play_opening_speech_sound() {
  const opening_speech = new Audio(opening_speech_audio);
  opening_speech.play();
}

const Modal_window = () => {
  const [isTextVisible, setTextVisible] = useState(false);

  const toggleTextVisibility = () => {
    setTextVisible((prevVisible) => !prevVisible);
  };

  return (
    <div
      className={`modal_massage ${isTextVisible ? 'modal_massage_open' : ''}`}
    >
      <img
        className={`pudge_icon ${isTextVisible ? 'pudge_icon_open' : ''}`}
        src={pudge_icon}
        alt="pudge_icon"
      />

      <img
        onClick={play_opening_speech_sound}
        className={`sound_icon ${isTextVisible ? 'sound_icon_open' : ''}`}
        src={sound_icon}
        alt="sound_icon"
      />

      <div className="welcome_text_wrapper">
        <button className="show_more_btn" onClick={toggleTextVisibility}>
          <img
            className={`arrow_bottom ${
              isTextVisible ? 'arrow_bottom_open' : ''
            }`}
            src={arrow_bottom}
            alt="arrow_bottom"
          />
        </button>
        <div className="welcome_text">
          <div>Приветствую тебя, поросёнок, на сайте </div>
          <div>Dota 2 timer.</div>

          {isTextVisible && ( // Условно отображаем текст
            <div>
              <div>
                Выбери желаемый таймер, чтобы начать, а я сообщу тебе, когда
                время подойдет к концу.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal_window;
